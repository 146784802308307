import axios from 'axios'
import store from '@/store'


const customApi = axios.create({
    baseURL: 'https://api.eligynmed.ro'
})

customApi.interceptors.request.use(
    (config) => {
        const token = store.getters['token'];
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    }
)

customApi.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            store.dispatch('logout');
        }
        return Promise.reject(error);
    }
);

export {axios, customApi}
