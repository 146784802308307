import {createStore} from "vuex"
import {customApi} from "@/plugins/axios";
import {useToast} from "vue-toastification";
import router from '@/router';

export default createStore({
    state: {
        sidebarVisible: "",
        sidebarUnfoldable: false,
        token: localStorage.getItem('raportMedicalToken') ?? null,
        userId: localStorage.getItem('userId') ?? null,
        privileges: localStorage.getItem('privileges') ?? null,
        userName: localStorage.getItem('userName') ?? null,
        clientSelectedForEdit: null,
        clientSelectedForDelete: null,
        raportSelectedForEdit: null,
        raportSelectedForCopy: null,
        raportSelectedForDelete: null,
        raportSelectedForRead: null,
        raportSelectedForPdfPreview: null
    },
    mutations: {
        toggleSidebar(state) {
            state.sidebarVisible = !state.sidebarVisible
        },
        toggleUnfoldable(state) {
            state.sidebarUnfoldable = !state.sidebarUnfoldable
        },
        updateSidebarVisible(state, payload) {
            state.sidebarVisible = payload.value
        },
        updateToken(state, token) {
            localStorage.setItem('raportMedicalToken', token);
            state.token = token;
        },
        updatePrivileges(state, privileges) {
            localStorage.setItem('privileges', JSON.stringify(privileges));
            state.privileges = JSON.stringify(privileges);
        },
        updateUserId(state, userId) {
            localStorage.setItem('userId', userId);
            state.userId = userId;
        },
        updateUserName(state, userName) {
            localStorage.setItem('userName', userName);
            state.userName = userName;
        },
        updateClientSelectedForEdit(state, client) {
            state.clientSelectedForEdit = client;
        },
        updateClientSelectedForDelete(state, client) {
            state.clientSelectedForDelete = client;
        },
        updateRaportSelectedForEdit(state, raport) {
            state.raportSelectedForEdit = raport;
        },
        updateRaportSelectedForCopy(state, raport) {
            state.raportSelectedForCopy = raport;
        },
        updateRaportSelectedForDelete(state, raport) {
            state.raportSelectedForDelete = raport;
        },
        updateRaportSelectedForRead(state, raport) {
            state.raportSelectedForRead = raport;
        },
        updateRaportSelectedForPdfPreview(state, raport) {
            state.raportSelectedForPdfPreview = raport;
        }
    },
    actions: {
        async auth({commit}, credentials) {
            const toast = useToast();
            try {
                const response = await customApi.post('/login', {
                    "name": credentials.username,
                    "password": credentials.password
                });
                if (response.data.token) {
                    commit('updateToken', response.data.token);
                    commit('updateUserId', response.data.user.id);
                    commit('updatePrivileges', response.data.privileges);
                    commit('updateUserName', response.data.user.name);
                    router.push('/');
                } else {
                    toast.error('Autentificare eșuată!');
                }
            } catch (error) {
                toast.error('Autentificare eșuată!');
            }
        },
        async logout({commit}) {
            const toast = useToast();
            if (localStorage.getItem('raportMedicalToken')) {
                toast.error('Sesiunea a expirat!');
            }
            commit('updateToken', false);
            commit('updatePrivileges', null);
            localStorage.removeItem('raportMedicalToken');
            router.push('/auth');
        }
    },
    getters: {
        isLogged: (state) => state.token !== null && state.token !== false,
        token: (state) => state.token,
        clientSelectedForEdit: (state) => state.clientSelectedForEdit,
        clientSelectedForDelete: (state) => state.clientSelectedForDelete,
        raportSelectedForEdit: (state) => state.raportSelectedForEdit,
        raportSelectedForCopy: (state) => state.raportSelectedForCopy,
        raportSelectedForDelete: (state) => state.raportSelectedForDelete,
        raportSelectedForRead: (state) => state.raportSelectedForRead,
        raportSelectedForPdfPreview: (state) => state.raportSelectedForPdfPreview,
        userId: (state) => state.userId,
        userName: (state) => state.userName,
        privileges: (state) => state.privileges,
        hasPrivilege: (state) => (privilege) => {
            const privileges = JSON.parse(state.privileges);
            if(!privileges){
                return false;
            }
            return privileges.filter(p => p.privilege === privilege && p.active === "1").length > 0;
        }
    },
    modules: {},
})
