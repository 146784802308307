import store from "@/store";

export function hasPrivilege(to, from, next) {
    let requiredPrivilege = '';
    if (to.name === 'Acasa') {
        requiredPrivilege = 'report';
    } else if (to.name === 'Programare') {
        requiredPrivilege = 'booking';
    }
    const priv = store.getters.hasPrivilege(requiredPrivilege);
    if (priv) {
        next();
    }else{
        if(requiredPrivilege === 'report'){
            next('/bookings');
        }
    }
}

