import store from "@/store";

export function requireAuth(to, from, next) {
    const isAuthenticated = store.getters['isLogged'];
    if (isAuthenticated) {
        next();
    } else {
        store.dispatch('logout');
        next('/auth');
    }
}

