import DefaultLayout from "@/layouts/DefaultLayout.vue"
import {requireAuth} from "@/router/guards/auth";
import {hasPrivilege} from "@/router/guards/privilege";

const routes = [
    {
        path: "/",
        name: "Home",
        component: DefaultLayout,
        redirect: "/dashboard",
        beforeEnter: requireAuth,
        children: [
            {
                path: "/dashboard",
                name: "Acasa",
                beforeEnter: hasPrivilege,
                component: () => import("@/pages/Dashboard.vue"),
            },
            {
                path: "/bookings",
                name: "Programare",
                beforeEnter: hasPrivilege,
                component: () => import("@/pages/Booking.vue"),
            },
        ],
    },
    {
        name: "auth",
        path: "/auth",
        component: () => import("@/pages/Login.vue")

    },
    // {
    //     path: "/:catchAll(.*)*",
    //     component: () => import("pages/Error404.vue"),
    // },
]

export default routes
