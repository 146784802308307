import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import CoreuiVue from "@coreui/vue"
import CIcon from "@coreui/icons-vue"
import { iconsSet as icons } from "@/assets/icons"
import DocsExample from "@/components/DocsExample"
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas,far,fab)

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Toast)
app.provide("icons", icons)
app.component("CIcon", CIcon)
app.component("DocsExample", DocsExample)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('QuillEditor', QuillEditor)
app.mount("#app")
