<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
    <img :src="logoNegative" class="img-responsive" style="max-width:150px">
      <CIcon
        custom-class-name="sidebar-brand-narrow"
        :icon="sygnet"
        :height="35"
      />
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import { AppSidebarNav } from "./AppSidebarNav"
import  logoNegative  from "@/assets/brand/logo.png"
import { sygnet } from "@/assets/brand/sygnet"
export default {
  name: "AppSidebar",
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>

<style>
.sidebar-nav,
.sidebar-toggler{
    background: white !Important;
    color: black !important;
}
.sidebar-nav * {
    color: black !important;
    overflow: hidden;
}
.sidebar-nav .nav-link.active{
    background: #f0f0f0 !important;
    font-weight: bold !important;
}
.sidebar-brand{
    background: white !Important
}
</style>
