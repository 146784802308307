export default [

  {
    component: "CNavTitle",
    name: "Menu",
  },
  {
    component: "CNavItem",
    name: "Raport medical",
    to: "/dashboard",
    icon: "cil-speedometer",
    privilege: "report",
    badge: {
      color: "primary",
    },
  },
  {
    component: "CNavItem",
    name: "Programare",
    to: "/bookings",
    icon: "cil-calendar",
    privilege: "booking",
  },
  // {
  //   component: "CNavItem",
  //   name: "Rapoarte",
  //   to: "/reports",
  //   icon: "cil-notes",
  // },
]
