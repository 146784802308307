<template>
  <CFooter>
    <div>
      <a href="https://eligynmed.ro" target="_blank">ELIGYNMED</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }}.</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://korelvision.com" target="_blank">
          <img :src="korelvisionLogo" style="max-width: 180px">
      </a>
    </div>
  </CFooter>
</template>

<script>
import korelvisionLogo from "@/assets/images/korelvision.png"
export default {
  name: "AppFooter",
    setup() {
        return {
            korelvisionLogo: korelvisionLogo,
        }
    },
}
</script>
