<template>
    <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
            <p style="display: inline-block;margin-right: 15px;"> {{loggedInUserName}}</p>
            <CAvatar v-if="this.loggedInUserId === '1'" :src="avatar" size="md"/>
            <CAvatar v-else :src="avatar2" size="md"/>
        </CDropdownToggle>
        <CDropdownMenu class="pt-0">
            <CDropdownItem @click="logout">
                <CIcon icon="cil-lock-locked"/>
                Logout
            </CDropdownItem>
        </CDropdownMenu>
    </CDropdown>
</template>

<script>
import avatar from "@/assets/images/avatars/edina.jpg"
import avatar2 from "@/assets/brand/logo.png"
import store from "@/store";

export default {
    name: "AppHeaderDropdownAccnt",
    computed: {
        store() {
            return store
        },
        loggedInUserId(){
            return store.getters['userId'];
        },
        loggedInUserName(){
            return store.getters['userName'];
        }
    },
    setup() {
        return {
            avatar:  avatar,
            avatar2: avatar2,
            itemsCount: 42,
        }
    },
    methods: {
        logout() {
            this.store.dispatch('logout');
        }
    }
}
</script>
